import { Platform, PlatformIOSStatic } from 'react-native';
import { isIphoneX as rnIsIphoneX, getBottomSpace, getStatusBarHeight } from 'react-native-iphone-screen-helper';
import { NavigationState, PartialState } from '@react-navigation/native';
import { pathOr } from 'ramda';
import { analytics } from './firebase';

import mobileDetect from './mobileDetect';
import Alert from './alert';
import { envPlatform } from './environmentVariables';

// Env. variables
export {
  apmServerURL,
  apmSecretToken,
  env,
  authUrl,
  notificationUrl,
  coreUrl,
  sttUrl,
  sttPostDisabled,
  sttModel,
  envPlatform,
  appCoreUrl
} from './environmentVariables';

// Constants
export { MESSAGES_PER_PAGE } from './constants';

// Dates
export { convertToDateFrom, prettyDate, getTimezone, getDateString, formatDateValue, toDate, displayPickerDate, toISOString, formatDateRange } from './date';

// Lodash utils
export { default as clamp } from 'lodash/clamp';
export { default as debounce } from 'lodash/debounce';
export { default as get } from 'lodash/get';
export { default as includes } from 'lodash/includes';
export { default as isEmpty } from 'lodash/isEmpty';
export { default as isEqual } from 'lodash/isEqual';
export { default as isNil } from 'lodash/isNil';
export { default as isString } from 'lodash/isString';
export { default as map } from 'lodash/map';
export { default as round } from 'lodash/round';

// Ramda utils
// export { default as compose } from 'ramda/src/compose';
export { default as always } from 'ramda/src/always';
export { default as append } from 'ramda/src/append';
export { default as clone } from 'ramda/src/clone';
export { default as concat } from 'ramda/src/concat';
export { default as flip } from 'ramda/src/flip';
export { default as identity } from 'ramda/src/identity';
export { default as inc } from 'ramda/src/inc';
export { default as insert } from 'ramda/src/insert';
export { default as last } from 'ramda/src/last';
export { default as lensPath } from 'ramda/src/lensPath';
export { default as not } from 'ramda/src/not';
export { default as omit } from 'ramda/src/omit';
export { default as over } from 'ramda/src/over';
export { default as pathOr } from 'ramda/src/pathOr';
export { default as pipe } from 'ramda/src/pipe';
export { default as set } from 'ramda/src/set';
export { default as times } from 'ramda/src/times';
export { default as unless } from 'ramda/src/unless';
export { default as view } from 'ramda/src/view';
export { default as when } from 'ramda/src/when';
export { default as without } from 'ramda/src/without';

export const appRegistryName = 'Rollio';
export const isAndroid = Platform.OS === 'android';
export const isIos = Platform.OS === 'ios';
export const isWeb = Platform.OS === 'web';
export const isTablet = (Platform as PlatformIOSStatic).isPad;
export const isIphoneX = isTablet || rnIsIphoneX();
export const isChromeExtension = isWeb && envPlatform === 'chrome';
export const isSalesforceWebTab = isWeb && envPlatform === 'salesforceWebTab';
// TODO: Improve accuracy of this to only target tablets with a home bar
export const iphoneXHomeBarHeight = isTablet ? 20 : getBottomSpace() - (isIos ? 15 : 0);

export const statusBarHeight = getStatusBarHeight(true);
export const isSpeechSupported = !isWeb || (isWeb && mobileDetect.os() !== 'iOS') || (isWeb && mobileDetect.os() === 'iOS' && mobileDetect.is('Safari'));

export enum messageTypes {
  TEXT = 0,
  PICKLIST = 1,
  TABLE = 2,
  SEARCH = 3,
  QUICK_REPLY = 4,
  MULTI_PICKLIST = 5
}

export const filterHiddenConfirmationValues = (value: any) => !value.hideInConfirmation || value?.hideInConfirmation === 'false';

export const EditType = {
  Text: 'Text',
  LongTextArea: 'LongTextArea',
  Currency: 'Currency',
  Date: 'Date',
  Picklist: 'Picklist',
  MultiPicklist: 'MultiPicklist',
  Checkbox: 'Checkbox',
  Lookup: 'Lookup',
  MultiLookup: 'MultiLookup'
};
export const boolToNum = (bool: boolean): number => (bool ? 1 : 0);

export const getCurrentRouteName = (navigationState?: PartialState<NavigationState>): string | null => {
  if (!navigationState) {
    return null;
  }

  const { index, routes } = navigationState;
  const route = routes[Number(index)];

  if (route?.state) {
    return getCurrentRouteName(route.state);
  }

  return route.name;
};

export const webStorageKeys: WebStorageKeys = {
  loginEmail: '@rollio-app/email'
};

export const toDataTestIdFriendly = (text = 'NO-VALUE') => text.replace(/ /g, '-').toLowerCase();

export const trackEvent = async (name?: string, data?: any) => {
  analytics().logEvent(name!, data);
};

export const setAnalyticsUserProps = async ({ email, deployment_id, organization_id }: any) => {
  analytics().setUserProperties({
    email: `${email}`,
    deployment_id: `${deployment_id}`,
    organization_id: `${organization_id}`
  });
};

export const removeAnalyticsUserProps = async () => {
  analytics().setUserProperties({
    email: null,
    deployment_id: null,
    organization_id: null
  });
};

export const walkthroughStorageKey = 'HAS_VIEWED_WALKTHROUGH_V4.0.0';

export const getDependantOptions = (dependency: DependantAllowedValue, value: any, defaults: string[]): string[] => {
  const dependencyOptions = dependency.parentValues.find(p => p.value === value);

  return dependencyOptions ? pathOr(defaults, ['allowedValues'], dependencyOptions) : defaults;
};

export const nativeAlert = ({
  message,
  messageWeb,
  okLabel = 'OK',
  onOkPress,
  title
}: {
  message: string;
  messageWeb?: string;
  okLabel?: string;
  onOkPress: () => void;
  title: string;
}) => {
  Alert.alert(
    title,
    isWeb ? messageWeb || message : message,
    [
      {
        text: 'Cancel',
        style: 'cancel'
      },
      {
        text: okLabel,
        onPress: onOkPress
      }
    ],
    { cancelable: false }
  );
};

export const cancelConfirmationAlert = (onOkPress: () => void) =>
  nativeAlert({
    message: 'Are you sure you wish to cancel? \nYour current progress will be lost',
    onOkPress,
    title: 'Cancel'
  });
